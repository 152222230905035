<template>
  <div class="p-grid p-fluid">
    <Toast position="top-right" />
    <div class="p-col p-mx-auto">
      <Accordion class="p-mb-5">
        <AccordionTab header="Informations de l'entreprise">
          <div class="responsive-three-column-grid p-mb-4">
            <Card>
              <template #title> Entreprise </template>
              <template #content>
                <!-- {{ tarifications }} -->
                <!-- {{ getPlanList?.data }} -->
                <div class="p-d-flex p-mb-2 p-jc-between p-ai-center">
                  <span>Nom:</span>
                  <span class="p-text-bold">{{ getPlanList?.data[0].entreprise.name }}</span>
                </div>
                <div class="p-d-flex p-mb-2 p-jc-between p-ai-center">
                  <span>E-mail :</span>
                  <span class="p-text-bold">{{ getPlanList?.data[0].entreprise.email }}</span>
                </div>
                <div class="p-d-flex p-mb-2 p-jc-between p-ai-center">
                  <span>Profil :</span>
                  <span class="p-text-bold">{{
                    this.tarifications.filter(
                      (e) => e.rang == getPlanList?.data[0].entreprise.rangSouscriptionEnCours
                    )[0]?.adherant
                  }}</span>
                </div>
                <div class="p-d-flex p-mb-2 p-jc-between p-ai-center">
                  <span>Nom du point focal :</span>
                  <span class="p-text-bold">{{ getPlanList?.data[0].entreprise.pointFocal.name }}</span>
                </div>
                <div class="p-d-flex p-mb-2 p-jc-between p-ai-center">
                  <span>Registre de commerce :</span>
                  <span class="p-text-bold">{{ getPlanList?.data[0].entreprise.tradeRegister }}</span>
                </div>
                <div class="p-d-flex p-mb-2 p-jc-between p-ai-center">
                  <span>Numéro d'identification fiscal :</span>
                  <span class="p-text-bold">{{ getPlanList?.data[0].entreprise.identificationNumber }}</span>
                </div>
                <div class="p-d-flex p-mb-2 p-jc-between p-ai-center">
                  <span>Forme Juridique :</span>
                  <span class="p-text-bold">{{ getPlanList?.data[0].entreprise.formeJuridique.libelle }}</span>
                </div>
                <div class="p-d-flex p-mb-2 p-jc-between p-ai-center">
                  <span>Pays :</span>
                  <span class="p-text-bold">{{ getPlanList?.data[0].entreprise.pays.name }}</span>
                </div>
                <div class="p-d-flex p-jc-between p-ai-center">
                  <span>Adresse :</span>
                  <span class="p-text-bold">{{ getPlanList?.data[0].entreprise.address }}</span>
                </div>
              </template>
            </Card>
            <Card>
              <template #title> Domaine(s) métier(s)</template>
              <template #content>
                <Listbox
                  v-model="selectedCity"
                  :options="getPlanList?.data[0].entreprise.domaineMetiers"
                  optionLabel="libelle"
                />
              </template>
            </Card>
            <Card>
              <template #title>
                <div class="p-d-flex p-jc-between p-ai-center">
                  Présentation
                  <Button :style="{ width: '40%' }" @click="openCreateModal()" label="Sauvegarder" icon="pi pi-plus" />
                </div>
              </template>
              <template #content>
                <Textarea
                  autofocus
                  v-model="value"
                  :autoResize="true"
                  :style="{ width: '100%', height: '17.5em' }"
                  cols="6"
                  rows="9"
                />
              </template>
            </Card>
          </div>
        </AccordionTab>
      </Accordion>
      <div class="p-d-flex p-jc-between" :style="{ gap: '1.9em', marginBottom: '.9em' }">
        <div class="p-d-flex p-jc-between">
          <Dropdown v-model="selectedYear" :options="years" optionLabel="name" placeholder="Choisir l'annee" />
          <Divider layout="vertical" />
          <SelectButton
            v-model="selectedType"
            :options="types"
            optionLabel="name"
            optionDisabled="disabled"
            :disabled="true"
          >
            <template #option="slotProps">
              <div class="car-option">
                <div class="p-px-3">{{ slotProps.option.name }}</div>
              </div>
            </template>
          </SelectButton>
          <Divider layout="vertical" />
          <FileUpload
          v-if="
            (!(this.isValidatorPublicationRole() || this.isAdminRole() || this.isSimpleAdminRole()) &&
            (getPlanList?.data[0].status !== 'F_CREATED') && (getPlanList?.data[0].status !== 'F_REJECTED'))"
            mode="basic"
            name="demo[]"
            url="./upload.php"
            accept="image/*"
            :maxFileSize="1000000"
            @upload="onUpload"
            :auto="true"
            chooseLabel="Charger le plan"
          />
        </div>
        <div class="p-d-flex p-jc-between" :style="{ gap: '1.9em' }">
          <Button
          v-if="
            (!(this.isValidatorPublicationRole() || this.isAdminRole() || this.isSimpleAdminRole()) &&
            (getPlanList?.data[0].status !== 'F_VALIDATED') && (getPlanList?.data[0].status !== 'F_SUBMITTED'))"
            label="Importer"
            :style="{ width: 'auto' }"
            icon="pi pi-arrow-circle-down"
            iconPos="right"
          />
          <Button
          v-if="
            (!(this.isValidatorPublicationRole() || this.isAdminRole() || this.isSimpleAdminRole()) &&
            (getPlanList?.data[0].status !== 'F_VALIDATED') && (getPlanList?.data[0].status !== 'F_SUBMITTED'))"
            label="Exporter"
            :style="{ width: 'auto' }"
            icon="pi pi-arrow-circle-up"
            iconPos="right"
          />
          <!-- {{ getPlanList?.data[0].status }} -->
          <span v-if="getPlanList" :class="'pcl-badge ' + getStatusClassName(getPlanList?.data[0])">
                {{ enumMap[getPlanList?.data[0].status] }}</span>
                <Button
            label="Timeline"
            :style="{ width: 'auto' }"
            class="p-button-outlined"
            icon="pi pi-align-justify "
            iconPos="right"
          />
        </div>
      </div>
      <div class="card card-w-title">
        <TabMenu  :activeIndex="activePlanStep" :model="items"  style="margin-bottom: 1rem"/>
        <keep-alive>
          <router-view />
        </keep-alive>
      </div>
    </div>
  </div>
</template>
<script>
import DateFormatMixin from '@/mixins/date-format';
import EnumFormatMixin from '@/mixins/enum-format';
import LoggedUserMixin from '@/mixins/logged-user';
import http from '@/services/http.service';
import { mapActions, mapGetters } from 'vuex';
// import { required } from '@vee-validate/rules';
// import { useVuelidate } from '@vuelidate/core';
// import { maxValue, minValue } from '@vuelidate/validators';
// import { SUBMIT_FAILED, SUBMIT_REQUESTED, SUBMIT_SUCCEED } from '@/mixins/app-conts';

export default {
  mixins: [DateFormatMixin, EnumFormatMixin, LoggedUserMixin],
  data() {
    return {
      tarifications: [],
      selectedCity: null,
      activePlanStep: 0,
      selectedYear: { name: '2023', code: '23' },
      years: [
        { name: '2022', code: '22' },
        { name: '2023', code: '23' },
      ],
      selectedType: { name: 'Plan', code: 'Pl' },
      types: [
        { name: 'Plan', code: 'Pl' },
        { name: 'Rapport', code: 'Ra', disabled: true },
      ],
      cities: [
        { name: 'Informatique', code: 'NY' },
        { name: 'Assurance', code: 'RM' },
        { name: 'Finance', code: 'LDN' },
        { name: 'Commerce', code: 'IST' },
        { name: 'Agriculture', code: 'PRS' },
      ],
      items: [
        {
          icon: 'pi pi-fw pi-users',
          label: 'Emploi',
          to: `/local-content/step/${this.$route.params.companyName}`,
          disabled:true,
          // command: event => {
          //   event.preventDefault();
          //       return;
          // }
        },
        {
          icon: 'pi pi-fw pi-id-card',
          label: 'Plan de succession - Salaires',
          to: `/local-content/step/${this.$route.params.companyName}/salary`,
          disabled:true,
        },
        {
          icon: 'pi pi-fw pi-bookmark',
          label: 'Formation',
          to: `/local-content/step/${this.$route.params.companyName}/training`,
          disabled:true,
        },
        {
          icon: 'pi pi-fw pi-briefcase',
          label: 'Approvisionnement en biens - travaux et services',
          to: `/local-content/step/${this.$route.params.companyName}/supply`,
          disabled:true,
        },
      ],
    };
  },
  watch:{
    $route(to,from){
      console.log(to,from);
      console.log(to.breadcrumb)
      switch (to?.meta?.breadcrumb[0]?.label) {
        case "Emploi":
          this.activePlanStep = 0;
          break;

          case "Salaires":
          this.activePlanStep = 1;
          break;
          case "Formations":
          this.activePlanStep = 2;
          break;
          case "Approvisionnement en biens - travaux et services":
          this.activePlanStep = 3;
          break;
      
        default:
          break;
      }
    }
  },

  async mounted() {
    this.token = localStorage.getItem('token');
    this.config = {
      headers: { Authorization: `Bearer ${this.token}` },
    };
    if (localStorage.token) {
      this.getTarifications();
      // console.log(this.$router.currentRoute._value)
      switch (this.$router.currentRoute._value.meta.breadcrumb[0]?.label) {
        case "Emploi":
          this.activePlanStep = 0;
          break;

          case "Salaires":
          this.activePlanStep = 1;
          break;
          case "Formations":
          this.activePlanStep = 2;
          break;
          case "Approvisionnement en biens - travaux et services":
          this.activePlanStep = 3;
          break;
      
        default:
          break;
      }
      this.fetchPlans({params: `companyName=${encodeURIComponent(this.$route.params.companyName.toLowerCase())}`});
    } else {
      this.$router.push('/login');
    }
  },
  computed: {
    ...mapGetters({
      getPlanList: 'localContent/getPlanList',
      getToReturn: 'localContent/getToReturn',
    }),
  },
  
  methods: {
    ...mapActions({
      fetchPlans: 'localContent/fetchPlans',
    }),
    async getTarifications() {
      await http.get('/tarifs?limit=100?sort=montant_xof,asc', this.config).then((res) => {
        this.tarifications = res.data;
        this.tarifications.sort((a, b) => b.montant_xof - a.montant_xof);
      });
    },
    getStatusClassName(localContent){
        switch (localContent.status) {
          case ('F_CREATED' || 'R_CREATED'):
            return 'create';
          case ('F_SUBMITTED' || 'R_SUBMITTED'):
            return 'submitted';
          case ('F_REJECTED' || 'R_REJECTED'):
            return 'rejected';
          case ('F_VALIDATED' || 'R_VALIDATED'):
            return 'validated';
          default:
            return '';
        }
      },
    nextPage(event) {
      console.log(event);
      //   for (let field in event.formData) {
      //       this.formObject[field] = event.formData[field];
      //   }

      //   this.$router.push(this.items[event.pageIndex + 1].to);
    },
    prevPage(event) {
      this.$router.push(this.items[event.pageIndex - 1].to);
    },
    complete() {
      this.$toast.add({
        severity: 'success',
        summary: 'Order submitted',
        detail: 'Dear, ' + this.formObject.firstname + ' ' + this.formObject.lastname + ' your order completed.',
      });
    },
  },
};
</script>
<style lang="scss">
.p-disabled, .p-component:disabled{
    opacity: 1;
}
.text-blue-300{
  color: #4baaf5;
}
.bg-blue-300{
  background-color: #4baaf5;
}
.p-accordion .p-accordion-content {
  background-color: transparent;
  padding: 0;
  padding-top: 1rem;
}
.truncate-custom {
  text-overflow: ellipsis;
  overflow: hidden;
}
.pi-file-export:before {
  content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' height='48' viewBox='0 -960 960 960' width='48'><path d='M450-160v-371L330-411l-43-43 193-193 193 193-43 43-120-120v371h-60ZM160-597v-143q0-24 18-42t42-18h520q24 0 42 18t18 42v143h-60v-143H220v143h-60Z'/></svg>");
}

.pi-file-import:before {
  content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' height='48' viewBox='0 -960 960 960' width='48'><path d='M452-202h60v-201l82 82 42-42-156-152-154 154 42 42 84-84v201ZM220-80q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h361l219 219v521q0 24-18 42t-42 18H220Zm331-554v-186H220v680h520v-494H551ZM220-820v186-186 680-680Z'/></svg>");
}
.w-2em {
  width: 1.6em;
}
.p-listbox .p-listbox-list {
  max-height: 10.5em;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
  font-size: large;
}

/* container */
.responsive-three-column-grid {
  display: block;
}

/* columns */
.responsive-three-column-grid > * {
  padding: 1rem;
}

/* tablet breakpoint */
@media (min-width: 768px) {
  .responsive-three-column-grid {
    display: grid;
    gap: 1em;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.pcl-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;
  
    &.create {
      background: #b5dbf0;
      color: #0288d1;
    }
  
    &.submitted {
      background: #feedaf;
      color: #fdc602;
    }

    &.rejected {
      background: #c63737;
      color: #e6a9a9;
    }
    &.validated {
      background: #256029;
      color: #9cf4a2;
    }
  }
</style>